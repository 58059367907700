import React, { useState, useEffect,useContext } from 'react'
import '../css/style.css';
import '../css/login1.css';
import { NavLink } from 'react-router-dom';
import "react-custom-flag-select/lib/react-custom-flag-select.min.css";
import { useNavigate,useSearchParams } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import {subApi} from '../../services/user-api';
import {serviceId,Loader,countryCode,flag,Random} from "../../utils/constant";
import { useTranslation } from "react-i18next";
import Select from "react-select"
import { LanguageContext } from "../LanguageContext"

const Ogb = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [flowLoading, setFlowLoading] = useState(false); 
    const [alreadySub, setAlreadySub] = useState(false);   
    const [flow, setFlow] = useState('');   
    const [phone, setPhone] = useState(localStorage.getItem("ugAlias")?localStorage.getItem("ugAlias"):'');
    const [subOption, setSubOption] = useState(1);
    const [plan, setPlan] = useState(1);
    const [price] = useState([0,60,350]);
    const [message, setMessage] = useState({message: "",error: false});
    const [otpId, setOtpId] = useState('');
    const [otp, setOtp] = useState('');
    const [ButtonDisabled, setButtonDisabled] = useState(true);
    const { t } = useTranslation("global"); 
    const [q] = useSearchParams();
    const [uniqueId, setUniqueId] = useState('');   
    const [subMode, setSubMode] = useState('WAP');
 

    useEffect(() => {      
        if(q.get("uid")){setUniqueId(q.get("uid"));setSubMode("APP");console.log("phone="+phone+":"+uniqueId);} 
        else {setUniqueId(Random());console.log("phone="+phone+"::"+uniqueId);}
        if (phone) { 
            if(q.get("uid")) // Promotion flow 
            {
                var nm=phone;if(nm.length<=10)nm=countryCode+nm
                subApi({action:3,serviceId:serviceId,ani:nm,mode:subMode,uid:uniqueId}).then((res) => {   
                    if(res.status===200)
                    { 
                        if(res.data.responseCode===202) // already subscribed 
                        {
                            localStorage.setItem("token", res.data.data.accessToken);
                            window.location.href ="/home";
                            //setAlreadySub(true);setFlow("SUCCESS");
                        }
                        else
                        {
                            setFlow("OTPCONFIRM");                      
                        }
                    }                
                    else {setMessage({message: res.data.message,error: true});}
                    setLoading(false);
                }).catch((err) => {
                    console.log(err);
                    setMessage({message: err.message, error: true });
                    setLoading(false);
                });
            }
            else
            {
                subApi({action:0,ani: phone,serviceId:serviceId}).then((res) => {
                console.log(res);                 
                if(res.data.data!==null && res.data.data!==undefined)
                {
                    setFlowLoading(false);
                    localStorage.setItem("token", res.data.data.accessToken);
                    window.location.href ="/home";
                }
                else
                {
                    setFlowLoading(false);
                    setFlow("PACK");//
                }
                }).catch((err) => {
                    console.log(err);
                    setFlowLoading(false);
                    setFlow("PACK");
                });
            }
        }        
    }, []);   
   

    const handleButtonClick = async() => {
        //console.log(phone);
        setLoading(true);
        await subApi({action:1,serviceId:serviceId,planId:plan,ani:phone,mode:subMode,uid:uniqueId}).then((res) => {            
            console.log(res)
            localStorage.setItem("token", res.data.data.accessToken);
            setFlow("SUCCESS");
        }).catch((err) => {
            console.log(err)
            setMessage({message: err.message,error: true})
        }).finally(() => setLoading(false))
      };

           
    const sendOtp = async() => {
        console.log('phone='+phone);
        setMessage({message: "",error: false});
        if(phone && phone.length>=8)
        {
            var nm=phone;if(nm.length<=10)nm=countryCode+nm
            setLoading(true);
            await subApi({action:3,serviceId:serviceId,ani:nm,mode:subMode,uid:uniqueId}).then((res) => {   
                if(res.status===200)
                { 
                    if(res.data.responseCode===202) // already subscribed 
                    {
                        localStorage.setItem("token", res.data.data.accessToken);
                        window.location.href ="/home";
                        //setAlreadySub(true);setFlow("SUCCESS");
                    }
                    else
                    {
                        setFlow("OTPCONFIRM");                      
                    }
                }                
                else {setMessage({message: res.data.message,error: true});}
                setLoading(false);
            }).catch((err) => {
                console.log(err);
                setMessage({message: err.message, error: true });
                setLoading(false);
            });
        }
        else if(phone && phone.length>0){setMessage({message: t("portalText.Msg Valid Number"),error: true})}
        else{setMessage({message: t("portalText.Msg Number"),error: true})}
       
       // navigate('/profile');
      };
    
      const confirmOtp = async() => {
        setMessage({message: "",error: false});     
        if(otp!=null && otp.length===4)
        {
            var nm=phone;if(nm.length<=8)nm=countryCode+nm
            setLoading(true);
            await subApi({action:4,serviceId:serviceId,ani: nm,otpId:otpId,otp:otp,mode:subMode,uid:uniqueId}).then((res) => {                    
                if(res.status===200)
                { 
                    if(res.data.responseCode===202) // already subscribed 
                    {
                        localStorage.setItem("token", res.data.data.accessToken);
                        window.location.href ="/home";
                        //setAlreadySub(true);setFlow("SUCCESS");
                    }
                    else
                    {
                        localStorage.setItem("ugAlias",nm);
                        if(subMode==='APP')
                        {
                            setPlan(1);
                            setFlow("DOUBLECONFIRM");
                        }
                        else setFlow("PACK");
                    }                    
                }                
                else {setMessage({message: res.data.message,error: true})}
            }).catch((err) => {
                setMessage({message: err.message, error: true});
            }).finally(() => setLoading(false))
        }
        else{setMessage({ message:t("portalText.Msg Valid OTP"),error: true });}
      }     

      const handleNumberChange = async(event) =>{
        const input = event.target.value;
        if(input.length<11)setPhone(input);
        setButtonDisabled(input.length <8);
    }  
    const handleRadioChange = async(input) =>{
        setPlan(input);
    }  
    const handleSubChange = async(input) =>{
        setSubOption(input);
    }    
    const handleSubOptionClick = async() => {
        if(subOption==2){setPlan(3);}
        else {setPlan(1);}
        setFlow("PACK");
    }
    const cancel = async(input) => {
        setMessage({message: "",error: false});
        setFlow(input);
    }
    const handleNumInputsChange = (numInputs) => {
        setOtp(numInputs);
        setButtonDisabled(numInputs.length !== 4 || isNaN(numInputs));
    }
    const redirectTo = async(input) => {
        if(input==="/home")window.location.href ="/home";
        else navigate(input);
      };

      
      // Language option //
      
      const { language, changeLanguage } = useContext(LanguageContext); 
      const handleChange = (selectedOption) => {
        changeLanguage(selectedOption.value)
      }
      const languages = [    
        { value: "pr", label: "Portuguese" },
        { value: "en", label: "English" },
        
      ]
      const selectedOption = languages.find((option) => option.value === language)
      const customStyles = {
        control: (provided) => ({
          ...provided,
          backgroundColor: "#d80000",
          fontSize: "18px",
          fontWeight: 700,
          color: "#fff",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
          borderRadius: "10px",
          border: "none",
          outline: "none",
          margin: "0 auto",
          minHeight: "40px",
        }),
        singleValue: (provided) => ({
          ...provided,
          color: "#fff",
        }),
        placeholder: (provided) => ({
          ...provided,
          color: "#fff",
        }),
        option: (provided, state) => ({
          ...provided,
          backgroundColor: state.isSelected ? "#d80000" : "#fff",
          color: state.isSelected ? "#fff" : "#000",
          cursor: "pointer",
        }),
        menu: (provided) => ({
          ...provided,
          borderRadius: "10px",
          marginTop: "5px",
        }),
      }
   

    return (  
        flowLoading? <Loader />
        :<>     
        <div className='home'>
            <div className="maine-div">
                <div className="container-fluid box-wrapper login"> 
                <div className='pt-5'>
                        <div className="row align-items-center">
                            <div className="col-4"></div>
                            <div className="col-4">
                                <div className="logo d-flex">
                                    {/* <img src="/images/uv_games_logo.png" alt="UVGames" /> */}
                                </div>
                            </div>

                            <div className="col-4">
                                <div className="pt-2">
                                        <Select
                                            menuPlacement="top"
                                            value={selectedOption}
                                            options={languages}
                                            onChange={handleChange}
                                            styles={customStyles}
                                            placeholder="Select Language"
                                        />        
                                </div>
                            </div>  
                        </div>
                    </div>              
                    <div className="login-header pb-4">
                        <div className="box-wrapper mx-auto text-center position-relative"> 
                            <span className="modal-level-title game-title">{t("portalText.ogbDesc")}</span>
                            <img className="ogMoney" src="/images/ogg.png" alt="UVGames" />
                            <span className="modal-level-title game-title">{t("portalText.ogbDesc1")}</span>
                        </div>
                    </div>
                    <div className="login-info">
                        <div className="login-thumbnail2">
                            <img src="/images/login_img.png" alt="UVGames" />
                        </div>

                        { flow==="PACK" ? (
                        <> 
                            <h3 className="header-title-green mb-3 mt-3 text-center">{t("portalText.Sub Plan")}</h3>                        
                            <div className="text-center radio">
                                <label>
                                    <input type="radio" id="rd1" value="1" checked={plan === 1} onChange={() => handleRadioChange(1)} />
                                    60 FCFA/{t("portalText.Day")}
                                </label>
                                <label>
                                    <input type="radio" id="rd2" value="2" checked={plan === 2} onChange={() => handleRadioChange(2)} />
                                    350 FCFA/{t("portalText.Week")}
                                </label>
                            </div>
                            <div className="text-center phone-info">
                                {message.message && <div className="mb-3"><p className={`alert alert-${message.error ? 'danger' : 'success'}`}>{message.message}</p> </div>}
                                {loading ? <Loader />:<button className="btn btn-primary btn-pm-custom" type="button" onClick={()=>setFlow('DOUBLECONFIRM')} >{t("portalText.Sub Button")}</button>}
                            </div>
                        </> 
                        ): flow === "OTPCONFIRM" ?(
                        <>
                             <h3 className="login-title mb-3 mt-3 text-center">{t("portalText.Enter OTP")}</h3>
                             <div className="phone-info">
                                <div className="otp_section">               
                                    <OtpInput
                                        value={otp}
                                        numInputs={4}
                                        renderInput={(props, index) => (
                                            <input   {...props}  type="tel" inputMode="numeric" />
                                        )}
                                        inputStyle="form-control form-otp"
                                        onChange={handleNumInputsChange}
                                    />
                                </div>
                                {message.message && <div className="mb-3"><p className={`alert alert-${message.error ? 'danger' : 'success'}`}>{message.message}</p> </div>}
                                {loading ? <Loader />:<button className="btn btn-primary btn-pm-custom" type="button" onClick={confirmOtp} disabled={ButtonDisabled}>{t("portalText.Confirm")}</button>}
                                <div className="resend_otp"><a className="send_otp_link" onClick={() => {cancel('')}}> &lt;&lt;&nbsp;{t("portalText.Back")}</a></div>
                            </div>
                        </>
                        ):flow === "DOUBLECONFIRM" ?(
                            <>
                                 <h3 className="login-title mb-3 mt-3 text-center">{t("portalText.doubleConfirm")+" "+price[plan]+"F/"+(plan===1?t("portalText.Day"):t("portalText.Week"))+" "+t("portalText.doubleConfirm1")}</h3>
                                 <h3 className="login-title mb-3 mt-3 text-center">{t("portalText.doubleConfirm2")}</h3>
                                 <button className="btn btn-primary btn-pm-green" type="button" onClick={handleButtonClick} >{t("portalText.YES")}</button>
                                 <button className="btn btn-primary btn-pm-red" type="button" onClick={()=>setFlow('')}>{t("portalText.NO")}</button>
                                
                            </>
                        ):flow === "SUCCESS" ?(
                        <>
                            {alreadySub?
                            <h3 className="login-title-green mb-3 mt-3 text-center">{t("portalText.AlreadySub")}</h3>
                            : <h3 className="login-title-green mb-3 mt-3 text-center">{t("portalText.SubSuccess")}</h3>
                            }
                            <h3 className="login-title mb-3 mt-3 text-center">{t("portalText.Access Service")}</h3>
                            {loading ? <Loader />:<button className="btn btn-primary btn-pm-custom" type="button" onClick={() => {redirectTo('/home')}}>{t("portalText.Click Here")}</button>}                          
                        
                        </>                        
                        ): (
                            <>    
                                <h3 className="login-title mt-3 text-center">{t("portalText.Welcome")}</h3>                            
                                <h3 className="login-title mb-3 mt-3 text-center">{t("portalText.ogbWelcome")}</h3>                              
                                <div className="phone-input">  
                                    <div className="has-validation input-group">
                                        <div className="dialing-code">
                                            <img src={"https://flagcdn.com/"+flag+".svg"} width="24" alt="Cameroon" />
                                            <span>+{countryCode}</span></div>
                                            <input                                    
                                            className="form-control form-mobile-no" 
                                            type="number" 
                                            inputMode="numeric" 
                                            value={phone}
                                            onChange={handleNumberChange}
                                            placeholder={t("portalText.Enter Number")}
                                        />             
                                        </div>                              
                                    {message.message && <div className="mb-3"><p className={`alert alert-${message.error ? 'danger' : 'success'}`}>{message.message}</p> </div>}                              
                                    {loading ? <Loader />:<button className="btn btn-primary btn-pm-custom" type="button" onClick={sendOtp} disabled={ButtonDisabled}>{t("portalText.Submit")}</button>}
                                </div>
                            </>
                        )}
                    </div>                    
                </div>
            </div>
        </div>
        </>
    )
}

export default Ogb